import axios from "axios"

function getAll({
  dealerName,
  temperatureTag,
  priceVariationTag,
  inspectionTag,
}) {
  return axios.post(
    `/get_vins_per_tags`,
    {
      dealer_name: dealerName,
      tags: {
        temperature_tag: temperatureTag,
        price_variation_tag: priceVariationTag,
        inspection_tag: inspectionTag,
      },
    },
    {
      headers: {
        "x-api-key": "PS1TeVIiEb2l2vrKLqc5r7inq4ono20AaqWDbQyO",
        "Content-Type": "application/json",
      },
    }
  )
}

const getVinsPerTags = ({
  temperatureTag,
  priceTag,
  locationId,
  make = "",
  searchText = "",
}) => {
  return axios.post(
    "/get_vins_per_tags",
    {
      location_id: locationId,
      search_text: searchText,
      make,
      tags: {
        temperature_tag: temperatureTag,
        price_variation_tag: priceTag,
        // inspection_tag: "no_inspect",
      },
    },
    {
      headers: {
        "x-api-key": "PS1TeVIiEb2l2vrKLqc5r7inq4ono20AaqWDbQyO",
        "Content-Type": "application/json",
      },
    }
  )
}

const getMakesPerDealer = ({ id }) => {
  return axios.get(`get_makes_per_dealer?location_id=${id}`, {
    headers: {
      "x-api-key": "PS1TeVIiEb2l2vrKLqc5r7inq4ono20AaqWDbQyO",
      "Content-Type": "application/json",
    },
  })
}

function getVinsPerTag({
  dealerName,
  temperatureTag,
  priceVariationTag,
  inspectionTag,
}) {
  return axios.post(
    `/get_vins_per_tags`,
    {
      dealer_name: dealerName,
      tags: {
        temperature_tag: temperatureTag,
        price_variation_tag: priceVariationTag,
        inspection_tag: inspectionTag,
      },
    },
    {
      withCredentials: true,
      headers: {
        "x-api-key": "PS1TeVIiEb2l2vrKLqc5r7inq4ono20AaqWDbQyO",
        "Content-Type": "application/json",
      },
    }
  )
}

// history_period 14, 30, 60, 90
function getMetricsPerVin({ vin, historyPeriod }) {
  return axios.get(
    `/get_metrics_per_vin?vin=${vin}&history_period=${historyPeriod}`,
    {
      headers: {
        "x-api-key": "PS1TeVIiEb2l2vrKLqc5r7inq4ono20AaqWDbQyO",
        "Content-Type": "application/json",
      },
    }
  )
}

const DataApi = {
  getAll,
  getVinsPerTag,
  getVinsPerTags,
  getMakesPerDealer,
  getMetricsPerVin,
}

export default DataApi
