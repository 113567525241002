/* eslint-disable no-undef */
import React from "react"
// eslint-disable-next-line no-undef
const cargurusFair = require("../../assets/cargurusFair.png")
const cargurusGood = require("../../assets/cargurusGood.png")
const cargurusGreat = require("../../assets/cargurusGreat.png")
//const cargurusHigh = require("../assets/cargurusHigh.png")
const cargurusOverpriced = require("../../assets/cargurusOverpriced.png")

const carsFair = require("../../assets/carsFair.png")
const carsGood = require("../../assets/carsGood.png")
const carsGreat = require("../../assets/carsGreat.png")

const edmundsFair = require("../../assets/edmundsFair.png")
const edmundsGood = require("../../assets/edmundsGood.png")
const edmundsGreat = require("../../assets/edmundsGreat.png")

const autoCaGood = require("../../assets/autoCaGood.png")
const autoCaGreat = require("../../assets/autoCaGreat.png")
const autoCaFair = require("../../assets/autoCaFair.png")
const autoCaAbove = require("../../assets/autoCaAbove.png")

const autoComGood = require("../../assets/autoComGood.png")
const autoComGreat = require("../../assets/autoComGreat.png")

import { Image, Box, Text } from "@chakra-ui/react"
import { AUTOTRADER, AUTOTRADER_CA } from "./Badges"

// badging_values:
//   cargurus.com:
//     "": -2
//     "GOOD": 3
//     "GREAT": 4
//     "FAIR": 2
//     "POOR": 1
//     "OVERPRICED": 0
//   edmunds.com:
//     "": -2
//     "GREAT": 4
//     "GOOD": 3
//     "FAIR": 2
//     "TITLE ISSUES REPORTED": 0
//     "ABOVE MARKET": 1
//   autotrader.com:
//     "": -2
//     "GREAT": 1
//     "GOOD": 0
//   cars.com:
//     "": -2
//     "GREAT": 2
//     "GOOD": 1
//     "FAIR": 0

const Images = [
  {
    type: "edmunds.com",
    image: {
      GOOD: edmundsGood,
      GREAT: edmundsGreat,
      FAIR: edmundsFair,

      "TITLE ISSUES REPORTED": "ucitaj sliku",
      "ABOVE MARKET": "UCIT",
    },
  },
  {
    type: "cargurus.com",
    image: {
      GOOD: cargurusGood,
      GREAT: cargurusGreat,
      FAIR: cargurusFair,
      POOR: "POOR",
      OVERPRICED: cargurusOverpriced,
    },
  },
  {
    type: "autotrader.com",
    image: {
      GOOD: autoComGood,
      GREAT: autoComGreat,
    },
  },
  {
    type: "cars.com",
    image: {
      GOOD: carsGood,
      GREAT: carsGreat,
      FAIR: carsFair,
    },
  },
  {
    type: "autotrader.ca",
    image: {
      GOOD: autoCaGood,
      GREAT: autoCaGreat,
      ABOVE: autoCaAbove,
      FAIR: autoCaFair,
    },
  },
]

const Badge = ({ type, badge }) => {
  const getImage = () => {
    if (type === AUTOTRADER && badge?.url?.includes(".ca")) {
      type = AUTOTRADER_CA
    }
    const data = Images.find((x) => x.type === type)
    if (!data) {
      return ""
    }
    return data.image[badge.value]
  }

  const getBadge = () => {
    if (getImage() !== "") return <Image width="105px" src={getImage()}></Image>
    else if (badge.value) return <Text>{badge.value}</Text>
  }

  if (!badge?.value) return null

  return (
    <a href={badge?.url} target="_blank" rel="noreferrer">
      <Box
        boxShadow="2px 2px 40px rgba(83, 85, 128, 0.1)"
        borderRadius="8px"
        gap="10px"
        background="#FFFFFF"
        height="45px"
        width="168px"
        alignItems="center"
        justifyContent="center"
        display="flex"
        cursor="pointer"
      >
        {getBadge()}
      </Box>
    </a>
  )
}
export default Badge
