import { Box } from "@chakra-ui/react"
import React from "react"
import Select from "react-select"

import LocationIcon from "./assets/location.png"

const SelectFilter = ({
  options,
  value,
  onChange,
  placeholder,
  height = "54px",
  style,
  showIcon,
}) => {
  const SelectComponent = ({ paddingLeft = "0px" } = {}) => (
    <Select
      options={options}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      styles={{
        control: (baseStyles) => ({
          paddingLeft,
          ...baseStyles,
          height,
          ...style,
        }),
      }}
      components={{
        IndicatorSeparator: () => null,
      }}
    />
  )

  if (showIcon) {
    return (
      <Box position="relative">
        <img
          src={LocationIcon}
          style={{
            height: 20,
            width: 20,
            position: "absolute",
            zIndex: 2,
            left: 24,
            top: 10,
            pointerEvents: "none",
          }}
          alt="Location"
        />
        {SelectComponent({ paddingLeft: 48 })}
      </Box>
    )
  }

  return SelectComponent()
}

export default SelectFilter
