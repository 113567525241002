import React, { useEffect, useState } from "react"
import { Line } from "react-chartjs-2"
import "chart.js/auto"
import { Box, Text } from "@chakra-ui/react"

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  elements: {
    point: {
      radius: 3,
      borderWidth: 3,
      pointRadius: 11,
      pointHoverRadius: 11,
    },
  },
  scales: {
    y: {
      display: false,
    },
  },
}

const LeadsChart = ({ labels, leads }) => {
  const [data, setData] = useState()

  useEffect(() => {
    adjustData()
  }, [leads, labels])

  const adjustData = () => {
    setData({
      labels,
      datasets: [
        {
          label: "Price",
          data: leads,
          borderColor: "transparent",
          backgroundColor: "#3F8CFF",
          borderWidth: 3,
        },
      ],
    })
  }

  if (!data) return null

  return (
    <Box padding="20px">
      <Text fontSize="12px" color="#77808D" fontWeight="600">
        Total leads
      </Text>
      <Box marginTop="15px">
        <Line height="84px" options={options} data={data} />
      </Box>
    </Box>
  )
}

export default LeadsChart
