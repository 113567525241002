/* eslint-disable no-undef */

import React, { useEffect, useState } from "react"
import {
  Heading,
  Text,
  Box,
  Flex,
  Divider,
  Image,
  CloseButton,
} from "@chakra-ui/react"
import ConversationalBox from "../components/common/ConversationalBox"
import DataApi from "../api/DataApi"
import Circle from "../components/common/Circle"
import Tag from "../components/common/Tag"
import SelectFilter from "../components/filters/SelectFilter"
// import StatusInfo from "../components/Status/StatusInfo"
import TouchpointList from "../components/Touchpoint/TouchpointList"
import Loader from "../components/Loader/Loader"
import IntentsList from "../components/Intents/IntentsList"
// import LeadsChart from "../components/Chart/LeadsChart"
import PriceLeads from "../components/Chart/PriceLeads"
import AppointmentList from "../components/Appointment/AppointmentList"
import Badges from "../components/Badge/Badges"
import LeadsChart from "../components/Chart/LeadsChart"
const backArrow = require("../assets/arrowBack.png")

const logo = require("../assets/horizontal_logo.png")

// eslint-disable-next-line no-unused-vars
const DUMMY = {
  id: {
    vin: "WAUKMAF44HN050571",
    stock_number: "N050571",
    "year-make-model": "2017 - Audi - A4",
  },
  vin_temperature: "warm",
  time_series_metrics: {
    timeline: [
      "2022-12-21",
      "2022-12-22",
      "2022-12-23",
      "2022-12-24",
      "2022-12-25",
      "2022-12-26",
      "2022-12-27",
      "2022-12-28",
      "2022-12-29",
      "2022-12-30",
      "2022-12-31",
      "2023-01-01",
      "2023-01-02",
      "2023-01-03",
    ],
    price: [
      "18298.0",
      "18298.0",
      "18298.0",
      "18298.0",
      "18298.0",
      "18298.0",
      "18298.0",
      "18298.0",
      "18298.0",
      "18298.0",
      "18298.0",
      "18298.0",
      "18298.0",
      "17498.0",
    ],
    total_leads: [
      "4.0",
      "4.0",
      "4.0",
      "4.0",
      "4.0",
      "4.0",
      "8.0",
      "8.0",
      "8.0",
      "10.0",
      "10.0",
      "10.0",
      "10.0",
      "10.0",
    ],
    new_leads: [
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
      "0.0",
    ],
  },
  conversations: {
    ongoing_conversations: "6.0",
    last_incoming_msg: "4.0",
    average_response_time: "0.3921568627450981",
  },
  appointments: {
    total: 0,
    last_appointment: null,
    next_appointment: null,
  },
  intents: {
    list: ["trades", "vehicle_recommendations"],
    counts: {
      vehicle_recommendations: 8,
      trades: 1,
    },
  },
  touchpoints: {
    list: [
      "",
      "CarGurus",
      "Capital One Digital Retail",
      "hgreg.com/google/formulaire-echange",
      "Capital One Digital Retail-Contact Only",
      "AutoTrader.com",
    ],
    counts: {
      "Capital One Digital Retail": 8,
      "": 5,
      CarGurus: 2,
      "Capital One Digital Retail-Contact Only": 8,
      "hgreg.com/google/formulaire-echange": 2,
      "AutoTrader.com": 2,
    },
  },
  badging: {
    "cargurus.com": {
      value: "",
      url: [],
    },
    "autotrader.com": {
      value: "",
      url: [],
    },
    "edmunds.com": {
      value: "",
      url: [],
    },
    "cars.com": {
      value: "",
      url: [],
    },
  },
}

const TIME_OPTIONS = [
  {
    value: "14",
    label: "14 days",
  },
  {
    value: "30",
    label: "30 days",
  },
  {
    value: "60",
    label: "60 days",
  },
  {
    value: "90",
    label: "90 days",
  },
]

const DetailsScreen = ({ selectedId, setSelectedId }) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState()
  const [selectedTime, setSelectedTime] = useState({
    value: "14",
    label: "14 days",
  })

  useEffect(() => {
    // todo api call
    loadData()
  }, [selectedTime])

  const loadData = () => {
    setLoading(true)
    DataApi.getMetricsPerVin({
      vin: selectedId,
      historyPeriod: selectedTime.value,
    })
      .then((response) => {
        setData(response.data.output)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const close = () => {
    setSelectedId("")
  }

  if (loading || !data) {
    return (
      <Box padding="25px 20px">
        <Flex justifyContent="center" alignItems="center">
          <Box display="flex" marginRight="auto" alignItems="center">
            <Image
              src={backArrow}
              width="14px"
              height="12px"
              mr="14px"
              cursor="pointer"
              onClick={close}
            ></Image>

            <Image height="24px" src={logo}></Image>
          </Box>

          <Box onClick={close}>
            <CloseButton marginLeft="5px" size="sm" color="#A4A5A6" />
          </Box>
        </Flex>
        <Loader />
      </Box>
    )
  }

  return (
    <div>
      <Box padding="25px 20px">
        {/* <p onClick={close}>close</p> */}
        <Flex justifyContent="center" alignItems="center">
          <Box display="flex" marginRight="auto" alignItems="center">
            <Image
              src={backArrow}
              width="14px"
              height="12px"
              mr="14px"
              cursor="pointer"
              onClick={close}
            ></Image>
            <Image height="24px" src={logo}></Image>
          </Box>

          <Box width="149px">
            <SelectFilter
              value={selectedTime}
              onChange={setSelectedTime}
              options={TIME_OPTIONS}
              placeholder="Select"
              height="42px"
              style={{
                borderWidth: 0,
                backgroundColor: "#F9FAFB",
                borderRadius: "8px",
              }}
            />
          </Box>
          <CloseButton
            onClick={close}
            marginLeft="5px"
            size="sm"
            color="#A4A5A6"
          ></CloseButton>
        </Flex>
        <Box marginTop="25px" display="flex" alignSelf="flex-start">
          <Tag
            tagName={`Stock #${data.id.stock_number}`}
            textColor="#0049C6"
            bgColor="#E4EFFF"
          ></Tag>
        </Box>
        <Heading
          fontStyle="normal"
          color="#0E0E0E"
          fontWeight="600"
          fontSize="18px"
          lineHeight="140%"
          marginTop="7px"
          marginBottom="3px"
        >
          {data.id["year-make-model"]}
        </Heading>
        <Text
          color="#80848D"
          fontWeight="400"
          fontSize="14px"
          lineHeight="17px"
        >
          VIN {data.id.vin}
        </Text>
        <Box marginTop="22px">
          {/* <StatusInfo vinTemperature={data.vin_temperature} /> */}
          <Badges badges={data.badging}></Badges>
        </Box>
      </Box>
      <Box padding="14px 20px">
        <PriceLeads
          price={data.time_series_metrics.price}
          labels={data.time_series_metrics.timeline}
          totalLeads={data.time_series_metrics.total_leads}
        />
      </Box>

      <Divider />
      <Box>
        <Box padding="25px 20px">
          <Text fontWeight="600" fontSize="18px" color="#0E0E0E">
            Conversational Inteligence
          </Text>
          <Flex justifyContent="center" alignItems="center" marginTop="16px">
            <ConversationalBox
              title="Total Conversation"
              number={data.conversations.ongoing_conversations}
              numberText=""
              imgSrc="users"
              colorNmb="#48AF80"
              marginRight="6px"
            ></ConversationalBox>
            <ConversationalBox
              title="Last Incoming Message"
              number={data.conversations.last_incoming_msg}
              // numberText="days ago"
              imgSrc="chat"
              colorNmb="#FFC800"
              marginRight="6px"
            ></ConversationalBox>
            <ConversationalBox
              title="Average Response Time"
              number={data?.conversations?.average_response_time?.substring(
                0,
                5
              )}
              numberText="hh:mm"
              imgSrc="time"
              colorNmb="#3F8CFF"
            ></ConversationalBox>
          </Flex>
          <Flex
            marginTop="23px"
            justifyContent="end"
            alignItems="center"
            gap="4px"
          >
            <Box marginRight="auto" display="flex" alignItems="center">
              <Circle imgSource="heart" colorNmb="#FFF6D7"></Circle>
              <Text
                fontSize="14px"
                fontWeight="600"
                lineHeight="19.6px"
                paddingLeft="7px"
              >
                Intents
              </Text>
            </Box>

            <IntentsList data={data.intents} />
          </Flex>
        </Box>

        <Divider />

        <LeadsChart
          leads={data.time_series_metrics.new_leads}
          labels={data.time_series_metrics.timeline}
        />

        {/* <LeadsChart data={data.new_leads} /> */}

        <Box padding="25px 20px">
          <Flex justifyContent="end" marginTop="23px">
            <Box alignItems="center" display="flex" marginRight="auto">
              <Circle imgSource="car" colorNmb="#EAF6F1"></Circle>
              <Text
                fontSize="14px"
                fontWeight="600"
                lineHeight="19.6px"
                paddingLeft="7px"
              >
                Popular Touchpoints
              </Text>
            </Box>
            <Flex marginLeft="36px" flexWrap="wrap" gap="4px">
              <TouchpointList data={data.touchpoints} />
            </Flex>
          </Flex>
        </Box>
      </Box>
      <Divider marginTop="23px" marginBottom="22px" color="#F6F8F9"></Divider>
      <AppointmentList data={data} />
    </div>
  )
}

export default DetailsScreen
