import React, { useEffect, useState } from "react"
import { ChakraProvider } from "@chakra-ui/react"
import "./App.css"
import Home from "./views/Home"
import queryString from "query-string"

function App() {
  const [token, setToken] = useState()

  useEffect(() => {
    const parsed = queryString.parse(window.location.href)
    setToken(parsed?.token)
  }, [])

  if (!token) return null

  return (
    <ChakraProvider>
      <Home token={token} />
    </ChakraProvider>
  )
}

export default App
