import React from "react"
import { Box, Flex, Text } from "@chakra-ui/react"
import Circle from "../common/Circle"
const AppointmentBox = ({
  title,
  number,
  numberText,
  imgName,
  imgBgColor,
  boxBgColor,
}) => {
  return (
    <Box
      backgroundColor={boxBgColor}
      borderRadius="8px"
      padding="16px 10px"
      width="172px"
    >
      <Flex alignItems="center">
        <Circle imgSource={imgName} colorNmb={imgBgColor}></Circle>

        <Box marginLeft="6px">
          <Text
            fontSize="12px"
            fontWeight="600"
            color="#646576"
            lineHeight="130%"
            width="136px"
          >
            {title}
          </Text>
          <Flex alignItems="baseline" gap="3px">
            <Text fontSize="24px" color="#0E0E0E" fontWeight="600">
              {number}
            </Text>
            <Text fontSize="12px" color="#0E0E0E" fontWeight="600">
              {numberText}
            </Text>
          </Flex>
        </Box>
      </Flex>
    </Box>
  )
}
export default AppointmentBox
