import React from "react"
import { Box, Text } from "@chakra-ui/react"

const Tag = ({ tagName, textColor, bgColor }) => {
  return (
    <Box
      borderRadius="20px"
      backgroundColor={bgColor}
      padding="6px 12px"
      textAlign="center"
      height="29px"
    >
      <Text color={textColor} fontSize="12px" fontWeight="700">
        {tagName}
      </Text>
    </Box>
  )
}
export default Tag
