import { Box } from "@chakra-ui/react"
import React from "react"
import Tag from "../common/Tag"

const IntentsList = ({ data }) => {
  if (!data) return null

  return data?.map((item) => (
    <Box key={item.key} marginRight="4px" marginBottom="3px">
      <Tag
        tagName={`${item.label} (${item.count})`}
        textColor="#7854F6"
        bgColor="#F4F0FF"
        marginRight="4px"
      />
    </Box>
  ))
}

export default IntentsList
