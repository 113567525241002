/* eslint-disable react/no-children-prop */
/* eslint-disable react/jsx-no-undef */
import React, { useEffect, useState } from "react"
import { Flex, Button, Box, Image, CloseButton, Text } from "@chakra-ui/react"
import VehicleItem from "../components/vehicle/VehicleItem"
import DataApi from "../api/DataApi"
import TagsFilter from "../components/filters/TagsFilter"
import DetailsScreen from "./Details"
import MatadorApi from "../api/MatadorApi"
import Loader from "../components/Loader/Loader"
import SearchInput from "../components/filters/SearchInput"
import SelectFilter from "../components/filters/SelectFilter"

// eslint-disable-next-line no-undef
const logo = require("../assets/horizontal_logo.png")

// const LOCATION_ID = "6331dcce6e82590012491d70"

const Home = ({ token }) => {
  const [searchTerm, setSearchTerm] = useState("")
  const [list, setList] = useState([])
  const [temperatureFilter, setTemperatureFilter] = useState()
  const [priceFilter, setPriceFilter] = useState()
  const [inspectFilter, setInspectFilter] = useState()
  const [selectedId, setSelectedId] = useState("")
  // eslint-disable-next-line no-unused-vars
  const [locations, setLocations] = useState([]) // show locations
  const [selectedLocationId, setSelectedLocationId] = useState("")
  const [loading, setLoading] = useState()

  const getMatadorLocations = async () => {
    const response = await MatadorApi.getLocations({ token })

    if (response?.data?.locations?.length > 0) {
      setSelectedLocationId({
        value: response?.data?.locations[0]._id,
        label: response?.data?.locations[0].name,
      })
      const formattedLocations = response?.data?.locations.map((item) => {
        return {
          value: item._id,
          label: item.name,
        }
      })
      setLocations(formattedLocations)
    }
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getData()
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])

  useEffect(() => {
    if (selectedLocationId) {
      getData()
    }
  }, [selectedLocationId, temperatureFilter, priceFilter])

  useEffect(() => {
    if (!selectedId) {
      getMatadorLocations()
    }
  }, [])

  const getData = async () => {
    const filters = {
      locationId: selectedLocationId?.value,
      searchText: searchTerm,
    }

    if (temperatureFilter?.value) {
      filters.temperatureTag = temperatureFilter?.value
      filters.priceTag = priceFilter?.value
    }

    if (priceFilter?.value) {
      filters.priceTag = priceFilter?.value
    }

    setLoading(true)

    DataApi.getVinsPerTags({
      ...filters,
      // inspectionTag: "inspect",
    })
      .then((response) => {
        setList(response?.data?.output)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const search = () => {}

  const renderItems = () => {
    if (list?.length === 0) {
      return (
        <Text
          textAlign="center"
          fontSize="18px"
          color="#77808D"
          fontWeight="600"
        >
          Data is not available (check if chose the right location)
        </Text>
      )
    }

    return list?.map((item) => (
      <VehicleItem
        key={item.vin}
        year={item.Year}
        make={item.Make}
        model={item.Model}
        stockId={item.StockNumber}
        inspectionTag={item.inspection_tag}
        priceTag={item.price_change_tag}
        temperatureTag={item.temperature_tag}
        vin={item.vin}
        setSelectedId={setSelectedId}
      />
    ))
  }

  if (selectedId) {
    return (
      <DetailsScreen selectedId={selectedId} setSelectedId={setSelectedId} />
    )
  }

  const close = () => {
    window.top.postMessage("falcon_widget_exit", "*")
  }

  return (
    <Box p="20px">
      <Flex justifyContent="center" alignItems="center" marginBottom="23px">
        <Box display="flex" marginRight="auto" flex="1">
          <Image height="24px" src={logo}></Image>
        </Box>

        <Box display="flex" flex="1" alignItems="center">
          <Box flex="1">
            <SelectFilter
              value={selectedLocationId}
              onChange={setSelectedLocationId}
              options={locations}
              style={{
                fontSize: "14px",
                backgroundColor: "#F6F8F9",
                height: "42px",
                borderWidth: 0,
              }}
              showIcon
            />
          </Box>

          <Box onClick={close}>
            <CloseButton marginLeft="5px" size="sm" color="#A4A5A6" />
          </Box>
        </Box>
      </Flex>
      <Flex alignItems="center">
        <SearchInput searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        <Button h="54px" w="126px" colorScheme="blue" onClick={search}>
          Search
        </Button>
      </Flex>

      <Box mt="16px" mb="22px">
        <TagsFilter
          temperatureFilter={temperatureFilter}
          setTemperatureFilter={(option) => setTemperatureFilter(option)}
          inspectFilter={inspectFilter}
          setInspectFilter={(option) => setInspectFilter(option)}
          priceFilter={priceFilter}
          setPriceFilter={(option) => setPriceFilter(option)}
        />
      </Box>
      {loading ? (
        <Loader></Loader>
      ) : (
        <Box h="100%" mt="25px" bg="#FCFCFD">
          {renderItems()}
        </Box>
      )}
    </Box>
  )
}

export default Home
