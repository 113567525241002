import React, { useEffect, useState } from "react"
import { Text, Box, Flex } from "@chakra-ui/react"
import Circle from "./Circle"

const ConversationalBox = ({
  title,
  number,
  numberText,
  imgSrc,
  colorNmb,
  marginRight,
}) => {
  const [boxTitle, setTitle] = useState(null)
  const [boxNumber, setNumber] = useState(null)
  const [boxNumberText, setNumberText] = useState(null)

  useEffect(() => {
    setTitle(title)
    setNumber(number)
    setNumberText(numberText)
  }, [])

  return (
    <Box
      boxShadow="2px 2px 40px rgba(83, 85, 128, 0.1)"
      borderRadius="8px"
      padding="16px 12px"
      width="172px"
      marginRight={marginRight}
    >
      <Flex gap="6px" marginBottom="10px">
        <Circle imgSource={imgSrc} colorNmb={colorNmb}></Circle>
        <Text width="90px" fontSize="12px" fontWeight="600" color="#646576">
          {boxTitle}
        </Text>
      </Flex>
      <Flex alignItems="baseline" gap="3px">
        <Text fontSize="24px" color="#0E0E0E" fontWeight="600">
          {boxNumber}
        </Text>
        <Text fontSize="12px" color="#0E0E0E" fontWeight="600">
          {boxNumberText}
        </Text>
      </Flex>
    </Box>
  )
}

export default ConversationalBox
