/* eslint-disable react/no-children-prop */
import { SearchIcon } from "@chakra-ui/icons"
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react"
import React from "react"

const SearchInput = ({ setSearchTerm, searchTerm }) => {
  return (
    <InputGroup mr="14px" backgroundColor="#F6F8F9">
      <InputLeftElement
        pointerEvents="none"
        children={<SearchIcon color="gray.300" ml="23px" mr="8px" mt="11px" />}
      />

      <Input
        placeholder="Search by Make, VIN or stock number"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        h="54px"
        fontSize="14px"
      ></Input>
    </InputGroup>
  )
}

export default SearchInput
