import { Alert, Box, Flex, Text } from "@chakra-ui/react"
import React from "react"

const VehicleItem = ({
  setSelectedId,
  stockId,
  // eslint-disable-next-line no-unused-vars
  inspectionTag,
  // eslint-disable-next-line no-unused-vars
  priceTag,
  temperatureTag,
  year,
  make,
  model,
  vin,
}) => {
  const checkColor = (temp) => {
    switch (temp) {
      case "very_cold":
        return "#0049C6"
      case "cold":
        return "#88c7dc"
      case "warm":
        return "#F9BB06"
      case "hot":
        return "#E05D4C"
      case "very_hot":
        return "#E00427"
      default:
    }
  }
  return (
    <Box
      p="32px"
      boxShadow="2px 2px 40px rgba(83, 85, 128, 0.1);"
      borderRadius="8px"
      mb="12px"
      bg="#fff"
      cursor="pointer"
      onClick={() => setSelectedId(vin)}
    >
      <Flex justifyContent="start">
        <Alert
          color="#0049C6"
          bg="#E4EFFF"
          mr="4px"
          h="20px"
          status="info"
          borderRadius="20px"
          fontSize="12px"
          fontWeight="600"
          width="auto"
        >
          Stock #{stockId}
        </Alert>

        <Alert
          h="20px"
          status="error"
          bg="white"
          style={{ color: `${checkColor(temperatureTag)}` }}
          borderRadius="20px"
          fontSize="12px"
          fontWeight="600"
          width="auto"
        >
          {temperatureTag}
        </Alert>
      </Flex>
      <Text mt="7px" mb="3px" fontSize="18px" fontWeight="600" color="#0E0E0E">
        {make} {model} {year}
      </Text>
      <Text fontSize="14px" fontWeight="400" color="#80848D">
        {vin}
      </Text>
    </Box>
  )
}

export default VehicleItem
