import { Flex } from "@chakra-ui/react"
import React, { useMemo } from "react"
import AppointmentBox from "../common/AppointmentBox"

const AppointmentList = ({ data }) => {
  const lastAppointment = useMemo(() => {
    if (data.appointments.last_appointment) {
      const date = new Date(data.appointments.last_appointment)

      return {
        month: date.toLocaleString("default", { month: "short" }),
        day: date.getDate(),
      }
    }
  }, [data])

  const nextAppointment = useMemo(() => {
    if (data.appointments.next_appointment) {
      const date = new Date(data.appointments.next_appointment)

      return {
        month: date.toLocaleString("default", { month: "short" }),
        day: date.getDate(),
      }
    }
    return ""
  }, [data])

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      gap="6px"
      paddingBottom="32px"
    >
      <AppointmentBox
        title="Total Appointments"
        number={data.appointments.total}
        numberText=""
        imgName="check"
        boxBgColor="#F4F0FF"
        imgBgColor="#7854F6"
      />
      <AppointmentBox
        title="Last Appointment"
        number={lastAppointment?.month || "-"}
        numberText={lastAppointment?.day}
        imgName="task"
        boxBgColor="#FFF8F0"
        imgBgColor="#EA9957"
      />
      <AppointmentBox
        title="Next Appointment"
        number={nextAppointment?.month || "-"}
        numberText={nextAppointment?.day}
        imgName="calendar"
        boxBgColor="#EAF6F1"
        imgBgColor="#48AF80"
      />
    </Flex>
  )
}

export default AppointmentList
