import React, { useEffect, useState } from "react"
import { Line } from "react-chartjs-2"
import "chart.js/auto"
import { Box, Text } from "@chakra-ui/react"

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  elements: {
    point: {
      radius: 3,
      borderWidth: 3,
    },
  },
  // scales: {
  //   yAxes: [
  //     {
  //       ticks: {
  //         autoSkip: true,
  //       },
  //     },
  //   ],
  // },
}

const LegendItem = ({ name, circleColor, backgroundColor }) => {
  return (
    <Box
      display="flex"
      borderRadius="8px"
      padding="9px 14px"
      backgroundColor={backgroundColor}
      marginLeft="12px"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        backgroundColor={circleColor}
        height="10px"
        width="10px"
        marginRight="10px"
        borderRadius="5px"
      />
      <Text color="#11142D" fontSize="12px" fontWeight="600">
        {name}
      </Text>
    </Box>
  )
}

const PriceLeads = ({ labels, price, totalLeads }) => {
  const [priceData, setPriceData] = useState()
  const [leadsData, setLeadsData] = useState()

  useEffect(() => {
    adjustData()
  }, [price, totalLeads, labels])

  const adjustData = () => {
    setPriceData({
      labels,
      datasets: [
        {
          label: "Price",
          data: price,
          borderColor: "#6C5DD3",
          backgroundColor: "#6C5DD3",
          borderWidth: 3,
        },
      ],
    })

    setLeadsData({
      labels,
      datasets: [
        {
          label: "Leads",
          data: totalLeads,
          borderColor: "#F9BB06",
          backgroundColor: "#F9BB06",
          borderWidth: 3,
        },
      ],
    })
  }

  if (!priceData || !leadsData) return null

  return (
    <Box>
      <Box
        justifyContent="flex-end"
        display="flex"
        flexDirection="row"
        marginBottom="10px"
      >
        <LegendItem
          backgroundColor="rgba(108, 93, 211, 0.05)"
          circleColor="#6C5DD3"
          name="Price Change"
        />
      </Box>
      <Line options={options} data={priceData} />
      <Box
        justifyContent="flex-end"
        display="flex"
        flexDirection="row"
        marginBottom="10px"
        marginTop="25px"
      >
        <LegendItem
          backgroundColor="rgba(249, 187, 6, 0.08)"
          circleColor="#F9BB06"
          name="Leads"
        />
      </Box>
      <Line options={options} data={leadsData} />
    </Box>
  )
}

export default PriceLeads
