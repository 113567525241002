import React from "react"
import Badge from "../Badge/Badge"
import { Flex } from "@chakra-ui/react"

export const CARGURUS = "cargurus.com"
export const AUTOTRADER = "autotrader.com"
export const EMUNDS = "edmunds.com"
export const CARSCOM = "cars.com"
export const AUTOTRADER_CA = "autotrader.ca"

const Badges = ({ badges }) => {
  const getData = (key) => {
    return badges[key]
  }

  const renderItems = () => {
    return (
      <Flex gap="12px">
        <Badge type={CARGURUS} badge={getData(CARGURUS)} />
        <Badge type={AUTOTRADER} badge={getData(AUTOTRADER)} />
        <Badge type={EMUNDS} badge={getData(EMUNDS)} />
        <Badge type={CARSCOM} badge={getData(CARSCOM)} />
      </Flex>
    )
  }
  return <div>{renderItems()}</div>
}
export default Badges
