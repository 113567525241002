import axios from "axios"

function getLocations({ token }) {
  return axios.get(
    `https://api.matador.ai/api/v1/locations?page=1&perPage=50&enabled=true`,
    {
      headers: {
        "x-api-key": "PS1TeVIiEb2l2vrKLqc5r7inq4ono20AaqWDbQyO",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  )
}

const MatadorApi = {
  getLocations,
}

export default MatadorApi
