import React from "react"
import { Box, Flex } from "@chakra-ui/react"
import SelectFilter from "./SelectFilter"

const TEMPERATURE_OPTIONS = [
  {
    value: "",
    label: "All",
  },
  {
    value: "very_cold",
    label: "Very Cold",
  },
  {
    value: "cold",
    label: "Cold",
  },
  {
    value: "warm",
    label: "Warm",
  },
  {
    value: "hot",
    label: "Hot",
  },
  {
    value: "very_hot",
    label: "Very Hot",
  },
]

const PRICE_VARIATION_OPTIONS = [
  {
    value: "no_change",
    label: "No Change",
  },
  {
    value: "price_decreased",
    label: "Price Decreased",
  },
  {
    value: "price_increased",
    label: "Price Increased",
  },
]

// const INSPECT_OPTIONS = [
//   {
//     value: "inspect",
//     label: "Inspect",
//   },
//   {
//     value: "no_inspect",
//     label: "No Inspect",
//   },
// ]

const TagsFilter = ({
  temperatureFilter,
  setTemperatureFilter,
  priceFilter,
  setPriceFilter,
  // inspectFilter,
  // setInspectFilter,
}) => {
  return (
    <Flex gap="12px">
      <Box flex="1">
        <SelectFilter
          value={temperatureFilter}
          onChange={setTemperatureFilter}
          options={TEMPERATURE_OPTIONS}
          placeholder="Temperature"
          style={{
            fontSize: "14px",
            backgroundColor: "#F6F8F9",
            height: "42px",

            borderWidth: 0,
          }}
        />
      </Box>
      <Box flex="1">
        <SelectFilter
          value={priceFilter}
          onChange={setPriceFilter}
          options={PRICE_VARIATION_OPTIONS}
          placeholder="Price Order"
          style={{
            fontSize: "14px",
            backgroundColor: "#F6F8F9",
            height: "42px",
            borderWidth: 0,
          }}
        />
      </Box>
    </Flex>
  )
}

export default TagsFilter
