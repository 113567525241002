/* eslint-disable no-undef */

import React from "react"
import { Box, Flex, Image } from "@chakra-ui/react"

// eslint-disable-next-line no-unused-vars
/* eslint-disable no-undef */

const Circle = ({ imgSource, colorNmb }) => {
  return (
    <Box
      borderRadius="50%"
      backgroundColor={colorNmb}
      width="32px"
      height="32px"
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        width="32px"
        height="32px"
      >
        <Image src={require(`../../assets/${imgSource}.png`)}></Image>
      </Flex>
    </Box>
  )
}
export default Circle
